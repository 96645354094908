import { Link } from 'gatsby';
import React from 'react'
import services from '../data/services.json';
import services_list from '../data/services_list.json';

export default function Services() {
    return (
        <div className="bg-white">
            <div id="services" className="anchor mx-4 lg:mx-16 py-10 lg:py-16 h-full pb-10">
                <div className="mb-4 px-6 sm:px-8 lg:px-16 2xl:px-28">
                    <h3 className="font-bold text-2.5xl md:text-3xl lg:text-4xl
                                        md:mt-2 lg:mt-3 xl:mt-4 text-darkblue-tf text-center md:text-left lg:text-left z-20">
                        Need something we don't have? Let's work on it together.
                    </h3>
                </div>
                {/* <div className="mb-4">
                    <h3 className="font-bold text-2xl lg:text-3xl xl:text-3xl md:mt-2 lg:mt-3 xl:mt-4 text-dark-tf font-content text-center">
                        Need something we don't have? Let's work on it together.
                    </h3>
                </div> */}
                <div className="mt-20 px-6 sm:px-8 lg:px-16 2xl:px-28 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8  flex justify-between items-stretch">
                    {
                        services_list.map((item) => (
                            <div key={item.title}>
                                {/* <div className="text-center md:text-left bg-accent-trans-tf p-10 rounded-2xl self-stretch h-full"> */}
                                <div className="text-center md:text-left bg-qapps-blue p-10 rounded-2xl self-stretch h-full">
                                    <div className="flex justify-center md:justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={item.svg} />
                                        </svg>
                                    </div>
                                    <div className="mt-6">
                                        <h3 className="head-font font-semibold text-xl md:text-2.5xl 4xl:text-3xl text-darkblue-tf">{item.title}</h3>
                                        <p className="font-normal text-xl 4xl:text-2xl mt-3 text-gray-500">{item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="w-full pt-12 px-4 sm:px-6 lg:pt-16 lg:px-8 flex items-center justify-center">

                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow-xl shadow-darkblue-tf/20">
                            <Link to="/howwecanhelp" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-l md:text-xl 4xl:text-3xl font-medium rounded-md text-white bg-darkblue-tf hover:bg-blue-tf "                        >
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
