import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/services"
import Clients from "../components/clients"
import Partners from "../components/partners"
import AboutUs from "../components/about-us"
import Products from "../components/products"
import Testimonials from "../components/testimonials"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Products title="Products" />
    <Services title="Services" />
    <Clients title="Clients" />
    <Partners title="Partners" />
    <Testimonials title="Testimonials" />
    <AboutUs title="About Us" />
  </Layout>
)

export default IndexPage
