import { Link } from 'gatsby';
import React from 'react'
import products from '../data/products.json';
import SuperEllipse, { Preset } from "react-superellipse";
import { StaticImage } from "gatsby-plugin-image"

export default function Products() {
    // const business_il = '../assets/illustrations/stylish/collaborate-1768781-0.svg';
    const business_il = '../assets/illustrations/stylish/8640447-removebg.png';
    const analytics_svg = "../assets/images/svg_icons/analytics.svg";
    const productivity_svg = "../assets/images/svg_icons/productive.svg";
    const scalable_svg = "../assets/images/svg_icons/scalable.svg";
    const logo_full_color = "../assets/logo/2024/timefree2024_wordmark_color.png";

    return (
        <>
            <div className="flex justify-between hero-card m-4 lg:m-16 lg:mt-10 2xl:m-28 h-full relative z-30 overflow-visible relative">
                {/* <div className="flex hero-card p-4 lg:p-16 lg:mt-10 h-full relative z-30 overflow-visible relative"> */}
                <div id="hero" className="w-full md:w-10/12 3xl:w-1/2 4xl:w-7/12 z-30 pb-10 md:pb-20">
                    {/* <main className="mt-10 w-full sm:w-8/12 md:w-7/12 lg:w-8/12 xl:w-8/12 3xl:w-11/12 px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"> */}
                    <main className="mt-10 w-full px-6 sm:px-8 lg:pl-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                        <div className="text-left">
                            <h1 className="text-center md:text-left text-dark-tf text-4xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl 4xl:text-7xl w-fulltracking-wide">
                                <span className="head-font leading-relaxed md:leading-14 lg:leading-15 font-medium">Future-proof your business with</span>
                                {/* <span className="brand-font-normal text-blue-tf leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="brand-font-medium text-blue-tf leading-relaxed md:leading-14 lg:leading-15"> timefree</span> */}
                                {/* <span className="brand-font-bold text-blue-tf leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="brand-font-black text-blue-tf leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15"> digital solutions</span> */}
                            </h1>
                            <div className='mt-4 flex justify-center md:justify-start'>
                                <div className='w-6/12 md:w-5/12'>
                                    <StaticImage className="flex object-contain overflow-visible" src={logo_full_color} placeholder="tracedSVG" alt="" />
                                </div>
                            </div>

                            {/* <div className="block md:hidden text-center sm:text-left  mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1">
                                <span className="font-semibold text-notsodark-tf text-xl xl:text-2xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                    <StaticImage className="h-6 w-6 mr-2 text-notsodark-tf" src={analytics_svg} placeholder="tracedSVG" alt="" />
                                    Data analytics solutions,
                                    <StaticImage className="h-6 w-6 mr-2 text-notsodark-tf" src={productivity_svg} placeholder="tracedSVG" alt="" />
                                    Productivity applications,
                                    <StaticImage className="h-6 w-6 mr-2 text-notsodark-tf" src={scalable_svg} placeholder="tracedSVG" alt="" />
                                    Scalable platforms
                                </span>
                            </div> */}
                            <div className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 flex flex-wrap justify-center md:justify-start">
                                <div className="flex items-center mr-2 md:w-full">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-notsodark-tf" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                                    </svg> */}
                                    {/* fbb03b */}
                                    <StaticImage className="h-8 w-8 mr-2 text-notsodark-tf" src={analytics_svg} placeholder="tracedSVG" alt="" />
                                    <p className="font-semibold text-gray-800 text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                        Data Analytics Solutions
                                    </p>
                                </div>
                                <div className="flex items-center mr-2 md:w-full">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-notsodark-tf" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                        <path fillRule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clipRule="evenodd" />
                                    </svg> */}
                                    {/* 3fa9f5 */}
                                    <StaticImage className="h-8 w-8 mr-2 text-notsodark-tf" src={productivity_svg} placeholder="tracedSVG" alt="" />
                                    <p className="font-semibold text-gray-800 text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                        Productivity Applications
                                    </p>
                                </div>
                                <div className="flex items-center mr-2 md:w-full">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-notsodark-tf" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                                    </svg> */}
                                    <StaticImage className="h-8 w-8 mr-2 text-notsodark-tf" src={scalable_svg} placeholder="tracedSVG" alt="" />
                                    <p className="font-semibold text-gray-800 text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                        Scalable Platforms
                                    </p>
                                </div>
                                <div className=" mt-4 inline-flex rounded-md shadow-xl shadow-blue-tf/20 w-full sm:w-3/4 md:w-full lg:w-1/2 4xl:w-4/12">
                                    <Link to="/contact" className="w-full inline-flex items-center align-middle justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-blue-tf  hover:bg-darkblue-tf">
                                        <span>Get started</span>
                                        <i className='ml-4 fa-regular fa-arrow-right'></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                {/* <div id="hero_image" className="px-auto flex justify-end overflow-visible absolute top-20 xxs:top-16 sm:top-0 md:top-10 lg:top-10 2xl:-top-10 right-0 lg:right-5 xl:right-0 w-7/12 sm:w-7/12 lg:w-1/2 hidden sm:block md:block z-10 opacity-50 xs:opacity-100"> */}
                <div id="hero_image" className="filter drop-shadow-xl self-center overflow-visible right-0 content-center w-11/12 3xl:w-1/2 4xl:w-5/12 hidden md:block z-10 opacity-50 xs:opacity-100">
                    <StaticImage
                        className="flex object-contain overflow-visible"
                        src={business_il}
                        placeholder="tracedSVG"
                        alt=""
                    />
                </div>
            </div>

            <div id="hero_image_sm" className="filter drop-shadow-xl w-full flex justify-center overflow-visible md:hidden z-10">
                <StaticImage
                    className="flex object-contain overflow-visible h-full w-10/12"
                    src={business_il}
                    placeholder="tracedSVG"
                    alt=""
                />
            </div>

            <div id="products" className="anchor bg-transparent mx-auto px-4 lg:px-16 2xl:px-28 pt-5 xs:pt-10 lg:pt-12 pb-24 z-30 relative">
                <div className="mb-4 px-6 sm:px-8 lg:px-16">
                    <h3 className="font-bold text-2.5xl md:text-3xl lg:text-4xl
                                        md:mt-2 lg:mt-3 xl:mt-4 text-darkblue-tf text-center md:text-left lg:text-left z-20">
                        Choose what you need
                    </h3>
                </div>
                {/* <div id="products_list" className="mt-14 px-6 sm:px-8 lg:px-8 xl:px-16 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4 gap-6">
                    {
                        products.map((item) => (
                            <div key={item.title} className="flex flex-col justify-center z-20">
                                <div className="py-6">
                                    <div className="h-65 rounded-3xl shadow-xl p-6 pb-0 md:pb-4 flex space-x-8" style={{ backgroundColor: item.bgcolor }}>
                                        <div className="flex flex-col space-y-1">
                                            <div className="flex justify-between items-start">
                                                <div className="w-3/4 lg:w-5/12 overflow-ellipsis">
                                                    <h2 className="head-font font-bold md:font-semibold lg:font-bold uppercase text-xl sm:text-2xl md:text-3xl lg:text-xl xl:text-xl pt-0" style={{ color: item.fontcolor }}>{item.title}</h2>
                                                </div>
                                                <SuperEllipse className="overflow-visible relative -top-16 -right-3 w-1/2 xl:w-3/7" r1={0.0586} r2={0.332} style={{ height: 160 }}>
                                                    <img className="object-cover h-full w-full" src={item.image} alt="" />
                                                </SuperEllipse>
                                            </div>
                                            <div className="text-gray-300 text-l md:text-xl line-clamp-2 relative -top-14 md:-top-6 lg:-top-10" style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                            <Link to={item.href} className="underline uppercase text-md md:text-md font-semibold relative -top-12 md:-top-4 lg:-top-4" style={{ color: item.fontcolor }}                                            >
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div> */}

                {/* <div id="products_list2" className="mt-14 px-6 sm:px-8 lg:px-8 xl:px-16 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4 gap-6">
                    {
                        products.map((item) => (
                            <div key={item.title} className="flex flex-col justify-center z-20">
                                <div className="py-6">
                                    <div className="h-65 rounded-3xl shadow-xl p-6 flex space-x-8" style={{ backgroundColor: item.bgcolor }}>
                                        <div className="flex flex-col space-y-1">
                                            <SuperEllipse className="overflow-visible relative -top-16 -mb-14 w-2/3" r1={0.0586} r2={0.332} style={{ height: 160 }}>
                                                <img className="object-cover h-full w-full" src={item.image} alt="" />
                                            </SuperEllipse>
                                            <div className="overflow-ellipsis pt-3">
                                                <h2 className="head-font font-bold md:font-semibold lg:font-bold uppercase text-xl sm:text-2xl md:text-3xl lg:text-xl xl:text-xl pt-0" style={{ color: item.fontcolor }}>{item.title}</h2>
                                            </div>
                                            <div className="text-gray-300 text-l md:text-xl line-clamp-2 pt-3" style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                            <Link to={item.href} className="underline uppercase text-md md:text-md font-semibold pt-3" style={{ color: item.fontcolor }}                                            >
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div> */}

                {/* <div id="products_list3" className="mt-14 px-6 sm:px-8 lg:px-8 xl:px-16 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4 gap-6">
                    {
                        products.map((item) => (
                            <div key={item.title} className="flex flex-col justify-center z-20">
                                <div className="py-6">
                                    <div className="rounded-3xl shadow-xl p-6 flex space-x-8" style={{ backgroundColor: item.bgcolor }}>
                                        <div className="flex flex-col space-y-1">
                                            <SuperEllipse className="overflow-visible relative w-full" r1={0.0586} r2={0.32} style={{ height: 160 }}>
                                                <img className="object-cover h-full w-full" src={item.image} alt="" />
                                            </SuperEllipse>
                                            <div className="overflow-ellipsis pt-3">
                                                <h2 className="head-font font-bold md:font-semibold lg:font-bold uppercase text-xl sm:text-2xl md:text-3xl lg:text-xl xl:text-xl pt-0" style={{ color: item.fontcolor }}>{item.title}</h2>
                                            </div>
                                            <div className="text-gray-300 text-l md:text-xl line-clamp-2 pt-3" style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                            <Link to={item.href} className="underline uppercase text-md md:text-md font-semibold pt-3" style={{ color: item.fontcolor }}                                            >
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div> */}

                {/* <div id="products_list3" className="mt-6 px-6 sm:px-8 lg:px-8 xl:px-16 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4 gap-6">
                    {
                        products.map((item) => (
                            <div key={item.title} className="flex flex-col justify-center z-20">
                                <div className="py-6">
                                    <div class="rounded-3xl shadow-xl" style={{ backgroundColor: item.bgcolor }}>
                                        <img src={item.image} alt="" style={{ height: 180 }} className="object-cover w-full rounded-3xl" />
                                        <div className="p-6">
                                            <h2 className="font-bold mb-2 text-2xl" style={{ color: item.fontcolor }}>{item.title}</h2>
                                            <p className=" mb-2 line-clamp-2" style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            <Link to={item.href} className="hover:text-purple-500 underline text-sm text-center" style={{ color: item.fontcolor }}>Read More </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div> */}

                <div id="products_list3" className="mt-6 px-6 sm:px-8 lg:px-8 xl:px-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4 place-content-start gap-6">
                    {
                        products.map((item) => (
                            <Link to={item.href} key={item.title} className="flex flex-col justify-start z-20 group hover:transform hover:scale-[1.01] transition-all duration-500 ease-in-out">
                                <div className="py-6">
                                    <div className={`rounded-3xl shadow-xl shadow-[${item.shadowcolor}]/20`} style={{ backgroundImage: `url(${item.image})`, backgroundSize: `cover`, backgroundRepeat: 'repeat-y', backgroundPositionX: 'center', backgroundPositionY: -40 }}>
                                        <div className="" style={{ height: 120 }}></div>
                                        <div className={`p-6 rounded-3xl relative top-5 shadow-xl shadow-[${item.shadowcolor}]/10 `} style={{ backgroundColor: item.bgcolor }}>
                                            <h2 className="head-font font-semibold mb-2 text-xl sm:text-lg lg:text-xl 4xl:text-3xl line-clamp-1" style={{ color: item.fontcolor }}>{item.title}</h2>
                                            {/* <p className="mb-2 line-clamp-none hidden group-hover:block group-hover:transform transition-all duration-500 ease-in-out 4xl:text-2xl " style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></p> */}
                                            <p className="mb-2 line-clamp-2 block group-hover:line-clamp-none 4xl:text-2xl transition-transform" style={{ color: item.fontcolor }} dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            <p className="hover:text-purple-500 underline text-md 4xl:text-2xl pt-2" style={{ color: item.fontcolor }}>Read More </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </>)
}