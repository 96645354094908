import React, { useState } from 'react'
import testimonials from '../data/client_testimonials.json';
import { StaticImage } from "gatsby-plugin-image"

export default function Testimonials() {

    const [selected, setSelected] = useState(testimonials[0]);

    function selectClient(id) {
        setSelected(testimonials[id]);
        console.log(id);
    }

    return (
        <>
            <div id="testimonials" className="anchor mb-10 px-10 pt-10 lg:pt-16 2xl:px-28">
                <h3 className="font-bold text-2xl lg:text-3xl xl:text-3xl md:mt-2 lg:mt-3 xl:mt-4 font-content text-center text-dark-tf">
                    What our clients say
                </h3>
                <div className="block lg:hidden mt-16 gap-y-8 gap-16 grid grid-cols-1 xl:grid-cols-3 justify-center">
                    {
                        testimonials.map((item) => (
                            <div key={item.name}>
                                <div className="bg-white p-6 rounded-xl">
                                    <div className="flex items-center">
                                        <img
                                            className="rounded-full h-24 object-contain shadow-xl"
                                            src={item.image}
                                        />
                                        <div className="flex flex-col ml-6">
                                            <p className="text-xl font-bold text-dark-tf">{item.name}</p>
                                            <p className="mt-1 text-sm text-gray-500">{item.position}</p>
                                            <p className="font-medium text-gray-700">{item.company}</p>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: item.testimonial }}></p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="mt-16 hidden lg:flex flex-wrap justify-center">
                    <div className="w-2/5 3xl:w-1/3">
                        {
                            testimonials.map((item) => (
                                <a key={item.name} onClick={() => { selectClient(item.id) }}>
                                    <div className={`p-6 rounded-xl mb-6 cursor-pointer transition-shadow ${selected.id == item.id ? 'bg-white shadow-xl' : ''}`}>
                                        <div className="flex items-center">
                                            <img
                                                className="rounded-full h-24 object-contain shadow-xl"
                                                src={item.image}
                                            />
                                            <div className="flex flex-col ml-6">
                                                <p className="text-xl font-bold text-dark-tf">{item.name}</p>
                                                <p className="mt-1 text-md text-gray-500">{item.position}</p>
                                                <p className="font-medium text-gray-700">{item.company}</p>
                                            </div>
                                        </div>
                                        {/* <div className="mt-6">
                                        <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: item.testimonial }}></p>
                                    </div> */}
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                    <div className="w-2/5 ml-16 flex items-stretch">
                        <p className="text-gray-700 text-xl self-center" dangerouslySetInnerHTML={{ __html: selected.testimonial }}></p>
                    </div>
                </div>
            </div>
        </>
    )
}