import React from 'react'
import clients from '../data/clients.json';
import { StaticImage } from "gatsby-plugin-image"

export default function Clients() {
    return (
        <>
            <div id="clients" className="anchor mb-10 px-10 pt-10 lg:pt-16 2xl:px-28">
                <h3 className="font-bold text-2xl lg:text-3xl xl:text-3xl md:mt-2 lg:mt-3 xl:mt-4 font-content text-center text-dark-tf">
                    Those who have trusted us
                </h3>
                <div className="mt-10 gap-y-8 gap-10 flex flex-wrap justify-center">
                    {
                        clients.map((item) => (
                            <div key={item.name}>
                                <div className="h-20 w-20 sm:h-28 sm:w-28 flex items-center">
                                    <img
                                        className="h-24 object-contain"
                                        src={item.logo}
                                    />
                                    {/* <StaticImage
                                        className="h-24 object-contain"
                                        src={item.logo}
                                        placeholder="tracedSVG"
                                        alt=""
                                    /> */}
                                    {/* <GatsbyImage className="h-24 object-contain" image={getImage(item.logo)} alt={item.name} /> */}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}