import React from 'react'
import corevalues from '../data/corevalues.json';

export default function AboutUs() {
    return (
        <div className="bg-white">
            <div id="aboutus" className="anchor mx-4 lg:mx-16 pt-10 lg:pt-16 h-full pb-10">
                <div className="mb-0 md:mb-2">
                    <h3 className="font-bold text-2xl lg:text-3xl xl:text-3xl md:mt-2 lg:mt-3 xl:mt-4 text-dark-tf font-content text-center">
                        Who we are
                    </h3>
                </div>
                <div className="flex justify-center text-center">
                    <div className="mt-5 md:w-10/12">
                        <h3 className="uppercase head-font font-semibold text-lg lg:text-xl xl:text-2xl 4xl:text-3xl mt-0 md:mt-2 lg:mt-3 xl:mt-4 text-darkblue-tf font-content">
                            Our Story
                        </h3>
                        <p className="font-normal text-lg 4xl:text-2xl mt-3 text-gray-700">Founded in 2013 with the mission of spreading the power of efficiency and productivity to people and organizations, Timefree is helping thousands of clients with their digital transformation and is one of the premier enterprise software companies in the Philippines that focuses on cloud solutions, data analytics platforms, and productivity applications.</p>
                    </div>
                </div>
                <div className="flex justify-center text-center">
                    <div className="mt-5 xl:w-10/12">
                        <div className="mt-10 px-6 sm:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-y-16 flex justify-between items-stretch">
                            <div key="vision" className="text-center bg-qapps-blue p-10 rounded-2xl self-stretch h-full">
                                <div className="flex justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </div>
                                <div className="mt-6">
                                    <h3 className="head-font font-semibold text-2xl md:text-2.5xl 4xl:text-3xl text-darkblue-tf">Vision</h3>
                                    <p className="font-normal text-xl 4xl:text-2xl mt-3 text-gray-700">A world where people can make the most out of their time.</p>
                                </div>
                            </div>
                            <div key="mission" className="text-center bg-qapps-blue p-10 rounded-2xl self-stretch h-full">
                                <div className="flex justify-center ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
                                    </svg>
                                </div>
                                <div className="mt-6">
                                    <h3 className="head-font font-semibold text-2xl md:text-2.5xl 4xl:text-3xl text-darkblue-tf">Mission</h3>
                                    <p className="font-normal text-xl 4xl:text-2xl mt-3 text-gray-700">To spread the power of efficiency and productivity to people and organizations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center text-center">
                    <div className="mt-5 xl:w-10/12">
                        <h3 className="uppercase head-font font-semibold text-lg lg:text-xl xl:text-2xl 4xl:text-3xl mt-2 md:mt-3 lg:mt-4 xl:mt-4 text-darkblue-tf font-content">
                            Our Core Values
                        </h3>
                        <div className="mt-8 px-6 sm:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-y-16 flex justify-between items-stretch">
                            {
                                corevalues.map((item) => (
                                    <div key={item.name}>
                                        <div className="text-center md:text-left bg-qapps-blue p-10 rounded-2xl self-stretch h-full">
                                            <div className="flex justify-center md:justify-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.svg} />
                                                </svg>
                                            </div>
                                            <div className="mt-6">
                                                <h3 className="uppercase head-font text-xl md:text-xl 4xl:text-3xl text-darkblue-tf">{item.name}</h3>
                                                <p className="font-normal text-lg 4xl:text-2xl mt-3 text-gray-700">{item.descrption}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}